import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { motion } from "framer-motion";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import slideInOutAnim from "../animations/slideInOut.json";
import { makeStyles } from "@material-ui/core/styles";

import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Timeline from "@material-ui/icons/Timeline";
import Code from "@material-ui/icons/Code";
import Group from "@material-ui/icons/Group";
import CustomInput from "components/CustomInput/CustomInput.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import Email from "@material-ui/icons/Email";
import InfoIcon from "@material-ui/icons/Info";
import Icon from "@material-ui/core/Icon";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import WorkIcon from "@material-ui/icons/Work";
import Button from "components/CustomButtons/Button.js";
import { Tooltip } from "@material-ui/core";
import Danger from "components/Typography/Danger.js";

import EmailValidator from "email-validator";

// GraphQL
import ggl from "graphql-tag";
import client from "../api/client";

import image from "assets/img/background_sensata_2.png";

const Register = () => {
  const useStyles = makeStyles(signupPageStyle);
  const classes = useStyles();

  const { gatewayId } = useParams();

  return (
    <>
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <motion.div
          className={classes.container}
          style={{ width: "100%", paddingTop: 0 }}
          variants={slideInOutAnim}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <Form gatewayId={gatewayId} />
        </motion.div>
      </div>
    </>
  );
};

// Get the get the gateway state
const generateGatewayQuery = (gatewayId) => {
  return ggl`
    query{
      gateway(id: ${gatewayId}) {
        state
      }
    }
  `;
};

// Create a new project
const generateCreateProjectMutation = ({
  password,
  email,
  name,
  organizationName,
  gId,
  pId,
  clients,
}) => {
  return ggl`
    mutation {
      createProject(adminPassword: "${password}",
        email: "${email}", name: "${name}", organizationName: "${organizationName}",
        gatewayId: ${gId}, id: "${pId}", numberOfClients: ${clients}) {
          id
          email
          name
          organizationName
          numberOfClients
          state
          gateway {
            id
            state
          }
        }
    }
  `;
};

const Form = ({ gatewayId }) => {
  const useStyles = makeStyles(signupPageStyle);
  const classes = useStyles();

  // To handle the manual transition between pages
  const history = useHistory();

  const useToolTipStyles = makeStyles(tooltipsStyle);
  const tooltipClasses = useToolTipStyles();

  // Error information
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  // Gateway information
  const [gatewayID, setGatewayID] = React.useState("SIG-" + gatewayId);
  const [gatewayError, setGatewayError] = React.useState("");
  const [invalidGateway, setInvalidGateway] = React.useState(
    isNaN(gatewayId) ? true : false
  );
  const updateGatewayId = (event) => {
    // Ensure that 'SIG-' is in the start of the gatewayID
    if (!event.target.value.substring(0, 4) === "SIG-") {
      setInvalidGateway(true);
      setGatewayError(`- Gateway ID must start with "SIG-"`);
      return;
    }
    // Validate to ensure that it's a number
    if (
      isNaN(event.target.value.substring(4)) ||
      event.target.value.substring(4).length !== 8
    ) {
      setInvalidGateway(true);
      setGatewayError(`- The number after "SIG-" is 8 digits`);
      return;
    }

    // Update the value of the gatewayID
    setGatewayID(event.target.value);

    setInvalidGateway(false);
    setGatewayError("");
  };
  const checkStatus = (event) => {
    if (invalidGateway) {
      return;
    }

    // Reset the error information
    setInvalidGateway(false);
    setGatewayError("");

    // Submit the query
    client
      .query({
        query: generateGatewayQuery(Number(gatewayID.substring(4))),
        errorPolicy: "all",
      })
      .then(({ data, loading, errors }) => {
        // Handle errors if there are any
        if (errors) {
          setInvalidGateway(true);
          setGatewayError(" - " + errors[0].message);
        }
      });
  };

  // Email information
  const [emailAddress, setEmailAddress] = React.useState("");
  const [invalidEmailAddress, setInvalidEmailAddress] = React.useState(null);
  const [emailAddressError, setEmailAddressError] = React.useState("");
  const updateEmailAddress = (event) => {
    // Validate to ensure that it's a valid email address
    if (!EmailValidator.validate(event.target.value)) {
      // Email address is invalid - handle this here and prevent sending of data
      setEmailAddressError(`- ensure it is of the format "me@example.com"`);
      setInvalidEmailAddress(true);
      return;
    }

    setEmailAddress(event.target.value);

    setInvalidEmailAddress(false);
    setEmailAddressError("");
  };

  // Name
  const [name, setName] = React.useState("");
  const [invalidName, setInvalidName] = React.useState(null);
  const [nameError, setNameError] = React.useState("");

  const updateName = (event) => {
    // Ensure the name has some characters
    if (event.target.value.length === 0) {
      setNameError(" - ensure you enter your name");
      setInvalidName(true);
    }

    setName(event.target.value);

    setInvalidName(false);
    setNameError("");
  };

  // Organization name
  const [organizationName, setOrganizationName] = React.useState("");
  const [invalidOrganizationName, setInvalidOrganizationName] = React.useState(
    null
  );
  const [organizationNameError, setOrganizationNameError] = React.useState("");

  const updateOrganizationName = (event) => {
    // Ensure the name has some characters
    if (event.target.value.length === 0) {
      setOrganizationNameError(" - ensure you enter your organization name");
      setInvalidOrganizationName(true);
      return;
    }

    setOrganizationName(event.target.value);

    setInvalidOrganizationName(false);
    setOrganizationNameError("");
  };

  // Project ID
  const [projectID, setProjectID] = React.useState("");
  const [invalidProjectID, setInvalidProjecID] = React.useState(null);
  const [projectIdError, setProjectIdError] = React.useState("");
  const updateProjectID = (event) => {
    // Validate to ensure that it's a correct projectID
    if (event.target.value.length < 3) {
      setProjectIdError("- should be at least 3 characters");
      setInvalidProjecID(true);
      return;
    } else if (event.target.value.length > 35) {
      setProjectIdError("- should be at most 35 characters");
      setInvalidProjecID(true);
      return;
    }

    setProjectID(event.target.value);
    setInvalidProjecID(false);
    setProjectIdError("");
  };

  // Password
  const [password, setPassword] = React.useState("");
  const [invalidPassword, setInvalidPassword] = React.useState(null);
  const [passwordError, setPasswordError] = React.useState("");
  const updatePassword = (event) => {
    // Validate to ensure that it's a correct password
    if (event.target.value.length === 0) {
      setInvalidPassword(true);
      setPasswordError("- no password present");
      return;
    }

    setPassword(event.target.value);
    setInvalidPassword(false);
    setPasswordError("");
  };

  const [noThinClients, setNoThinClients] = React.useState(0);
  const updateNoThinClients = (event) => {
    setNoThinClients(event.target.value);
  };

  const resetErrors = () => {
    setIsError(false);
    setErrorMessage("");

    setInvalidPassword(false);
    setPasswordError("");

    setInvalidProjecID(false);
    setProjectIdError("");

    setInvalidEmailAddress(false);
    setEmailAddressError("");

    setInvalidGateway(false);
    setGatewayError("");
  };

  // Tooltips
  const [gatewayTooltipActive, setGatewayTooltipActive] = React.useState(false);
  const [emailTooltipActive, setEmailTooltipActive] = React.useState(false);
  const [nameTooltipActive, setNameTooltipActive] = React.useState(false);
  const [
    organizationNameTooltipActive,
    setOrganizationNameTooltipActive,
  ] = React.useState(false);
  const [projectIdTooltipActive, setProjectIdTooltipActive] = React.useState(
    false
  );

  const submitForm = () => {
    // Reset the error message
    resetErrors();

    // Handle any form errors
    if (invalidGateway || invalidGateway === null) {
      setInvalidGateway(true);
      setGatewayError(
        `- Gateway ID must start with "SIG-" & the number after "SIG-" is 8 digits`
      );
    }
    if (invalidEmailAddress || invalidEmailAddress === null) {
      setInvalidEmailAddress(true);
      setEmailAddressError("- ensure it is of the format 'me@example.com'");
      return;
    }
    if (invalidProjectID || invalidProjectID === null) {
      setInvalidProjecID(true);
      setProjectIdError("- should be at least 3 characters");
      return;
    }
    if (invalidPassword || invalidPassword === null) {
      setIsError(true);
      setInvalidPassword(true);
      setPasswordError("- no password present");
      return;
    }
    if (invalidOrganizationName || invalidOrganizationName === null) {
      setIsError(true);
      setInvalidOrganizationName(true);
      setOrganizationNameError(" - no organization name present");
      return;
    }
    if (invalidName || invalidName === null) {
      setIsError(true);
      setInvalidName(true);
      setNameError(" - no name present");
      return;
    }

    // Submit the mutation
    client
      .mutate({
        mutation: generateCreateProjectMutation({
          password: password,
          email: emailAddress,
          name: name,
          organizationName: organizationName,
          gId: gatewayID.substring(4),
          pId: projectID,
          clients: noThinClients,
        }),
        errorPolicy: "all",
      })
      .then(({ data, errors, loading }) => {
        // Handle errors if there are any
        if (errors) {
          setIsError(true);
          setErrorMessage(errors[0].message);

          // If a gateway error, output
          if (errors[0].details.hasOwnProperty("gateway")) {
            setInvalidGateway(true);
            setGatewayError(" - " + errors[0].details.gateway[0]);
          }

          return;
        }

        // No errors, transition to the '/success' page
        history.push({
          pathname: "/success",
          state: { email: emailAddress, projectID: projectID },
        });
      });
  };

  return (
    <GridContainer justify="center">
      <GridItem>
        <Card className={classes.cardSignup}>
          <h2 className={classes.cardTitle}>Register Gateway</h2>
          <CardBody>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4} lg={5}>
                <InfoArea
                  className={classes.infoArea}
                  title="Marketing"
                  description="Here is some marketing information for the SENSATA products!"
                  icon={Timeline}
                  iconColor="rose"
                />
                <InfoArea
                  className={classes.infoArea}
                  title="Gateway Information"
                  description="Here is some information on the gateways that you will be registering!"
                  icon={Code}
                  iconColor="primary"
                />
                <InfoArea
                  className={classes.infoArea}
                  title="Community"
                  description="Here is some information on the SENSATA community!"
                  icon={Group}
                  iconColor="info"
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={8} lg={5}>
                {isError ? (
                  <Danger>
                    <p>
                      <b>{errorMessage}</b>
                    </p>
                  </Danger>
                ) : (
                  <> </>
                )}
                <form className={classes.form}>
                  <CustomInput
                    labelText={`Gateway ID ${gatewayError}`}
                    id="gatewayID"
                    error={invalidGateway}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                          <Tooltip
                            id="gateway-tooltip"
                            title="This can be found on the label attached to your gateway"
                            placement="bottom"
                            className={{ tooltip: tooltipClasses.tooltip }}
                            open={gatewayTooltipActive}
                          >
                            <FingerprintIcon
                              className={classes.inputAdornmentIcon}
                            />
                          </Tooltip>
                        </InputAdornment>
                      ),
                      onBlur: (event) => {
                        checkStatus(event);
                        setGatewayTooltipActive(false);
                      },
                      onChange: updateGatewayId,
                      onClick: (event) => {
                        setGatewayTooltipActive(true);
                      },
                      defaultValue: gatewayID,

                      placeholder: "Gateway ID...",
                    }}
                  />
                  <CustomInput
                    labelText={`Email Address ${emailAddressError}`}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    error={invalidEmailAddress}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                          <Tooltip
                            id="email-tooltip"
                            title="We will send your login details and setup your account with this email address"
                            placement="bottom"
                            className={{ tooltip: tooltipClasses.tooltip }}
                            open={emailTooltipActive}
                          >
                            <Email className={classes.inputAdornmentIcon} />
                          </Tooltip>
                        </InputAdornment>
                      ),
                      onBlur: (event) => {
                        setEmailTooltipActive(false);
                      },
                      onClick: (event) => {
                        setEmailTooltipActive(true);
                      },
                      onChange: updateEmailAddress,
                      placeholder: "Email...",
                    }}
                  />
                  <CustomInput
                    labelText={`Name ${nameError}`}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    error={invalidName}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                          <Tooltip
                            id="name-tooltip"
                            title="Your full name"
                            placement="top"
                            className={{ tooltip: tooltipClasses.tooltip }}
                            open={nameTooltipActive}
                          >
                            <EmojiPeopleIcon
                              className={classes.inputAdornmentIcon}
                            />
                          </Tooltip>
                        </InputAdornment>
                      ),
                      onBlur: (evemt) => {
                        setNameTooltipActive(false);
                      },
                      onClick: (event) => {
                        setNameTooltipActive(true);
                      },
                      onChange: updateName,
                      placeholder: "Name...",
                    }}
                  />
                  <CustomInput
                    labelText={`Organization Name ${organizationNameError}`}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    error={invalidOrganizationName}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                          <Tooltip
                            id="organization-name-tooltip"
                            title="The name of the organization you work for"
                            placement="top"
                            open={organizationNameTooltipActive}
                            className={{ tooltip: tooltipClasses.tooltip }}
                          >
                            <WorkIcon className={classes.inputAdornmentIcon} />
                          </Tooltip>
                        </InputAdornment>
                      ),
                      onBlur: (event) => {
                        setOrganizationNameTooltipActive(false);
                      },
                      onClick: (event) => {
                        setOrganizationNameTooltipActive(true);
                      },
                      onChange: updateOrganizationName,
                      placeholder: "Organization Name...",
                    }}
                  />
                  <CustomInput
                    labelText={`Project ID ${projectIdError}`}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    error={invalidProjectID}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                          <Tooltip
                            id="project-id-tooltip"
                            title="This will be the project ID that your gateway will be active under"
                            placement="top"
                            open={projectIdTooltipActive}
                            className={{ tooltip: tooltipClasses.tooltip }}
                          >
                            <InfoIcon className={classes.inputAdornmentIcon} />
                          </Tooltip>
                        </InputAdornment>
                      ),
                      onBlur: (event) => {
                        setProjectIdTooltipActive(false);
                      },
                      onClick: (event) => {
                        setProjectIdTooltipActive(true);
                      },
                      onChange: updateProjectID,
                      placeholder: "Project ID...",
                    }}
                  />
                  <CustomInput
                    labelText={`Password ${passwordError}`}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    error={invalidPassword}
                    inputProps={{
                      type: "password",
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      onChange: updatePassword,
                      placeholder: "Password...",
                    }}
                  />
                  <CustomInput
                    labelText="Number of Thin Clients"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                      type: "number",
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                          <DeviceHubIcon
                            className={classes.inputAdornmentIcon}
                          />
                        </InputAdornment>
                      ),
                      onChange: updateNoThinClients,
                      placeholder: "Number of Thin Clients...",
                    }}
                  />
                  <div className={classes.textCenter}>
                    <Button color="primary" onClick={() => submitForm()}>
                      Submit
                    </Button>
                  </div>
                </form>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default Register;
