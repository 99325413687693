import {ApolloClient} from "apollo-client"
import {ApolloLink} from "apollo-link"
import {InMemoryCache} from "apollo-cache-inmemory"
import {createHttpLink} from "apollo-link-http"
// import {onError} from "apollo-link-error"
// import {setContext} from "apollo-link-context"
import {hasSubscription} from "@jumpn/utils-graphql"
import * as AbsintheSocket from "@absinthe/socket"
import {createAbsintheSocketLink} from "@absinthe/socket-apollo-link"
import {Socket as PhoenixSocket} from "phoenix"

const HTTP_ENDPOINT = process.env.NODE_ENV === "production" ? "https://api.sensata.app/" :
  process.env.REACT_APP_API_ENDPOINT || "http://localhost:4000/"

// To handle the web socket for subscriptions
const WS_ENDPOINT = HTTP_ENDPOINT.replace(/^http/, "ws") + "socket"

// Create an HTTP link to the backend app's HTTP endpoint URL
const httpLink = createHttpLink({
  uri: HTTP_ENDPOINT
});

// Create a WebSocket link to the backend app's socket URL
const socketLink = createAbsintheSocketLink(
  AbsintheSocket.create(new PhoenixSocket(WS_ENDPOINT))
)

// Create a link that 'splits' requests based on Graphql operation type
//    Queries and mutations go through the HTTP link
//    Subscriptions go through the Websocket link
const link = new ApolloLink.split(
  operation => hasSubscription(operation.query),
  socketLink,
  httpLink
);

// Create the Apollo Client instance
const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache()
});

export default client;
