import React from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import { makeStyles } from "@material-ui/core/styles";

import slideInOutAnim from "../animations/slideInOut.json";
import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle";

import Button from "components/CustomButtons/Button.js";
import image from "assets/img/background_sensata_2.png";

const Home = () => {
  const useStyles = makeStyles(landingPageStyle);
  const classes = useStyles();

  return (
    <div
      className={classes.pageHeader}
      style={{
        backgroundImage: "url(" + image + ")",
        backgroundSize: "cover",
        backgroundPosition: "top center",
      }}
    >
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem>
            <motion.div
              className={classes.brand}
              variants={slideInOutAnim}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <h1>SCADA Demo</h1>
              <h3 className={classes.title}>
                Feel free to check out our registration form!
              </h3>
              <div>
                <Link to="/register/12345678">
                  <Button type="button" color="warning">
                    Register
                  </Button>
                </Link>
              </div>
            </motion.div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
};

export default Home;
