import React from "react";
// import { useParams, useHistory } from "react-router-dom";
import { motion } from "framer-motion";

import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";
import slideInOutAnim from "../animations/slideInOut.json";
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import CustomInput from "components/CustomInput/CustomInput";
import { InputAdornment } from "@material-ui/core";
import { Fingerprint } from "@material-ui/icons";
import Button from "components/CustomButtons/Button.js";
import Success from "components/Typography/Success.js";
import { Tooltip } from "@material-ui/core";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";

// GraphQL
import ggl from "graphql-tag";
import client from "../api/client";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import image from "assets/img/background_sensata_2.png";

const Gateway = () => {
  const useStyles = makeStyles(signupPageStyle);
  const classes = useStyles();

  return (
    <div
      className={classes.pageHeader}
      style={{
        backgroundImage: "url(" + image + ")",
        backgroundSize: "cover",
        backgroundPosition: "top center",
      }}
    >
      <motion.div
        className={classes.container}
        style={{ width: "100%", paddingTop: 0 }}
        variants={slideInOutAnim}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <Form />
      </motion.div>
    </div>
  );
};

const generateCheckInGatewayMutation = ({ id }) => {
  return ggl`
    mutation {
      checkinGateway(id: ${id}) {
        endpoint
        id
        state
      }
    }
  `;
};

const Form = () => {
  const useStyles = makeStyles(signupPageStyle);
  const classes = useStyles();

  const useToolTipStyles = makeStyles(tooltipsStyle);
  const tooltipClasses = useToolTipStyles();

  // Gateway information
  const [gatewayID, setGatewayID] = React.useState("SIG-");
  const [gatewayError, setGatewayError] = React.useState("");
  const [invalidGateway, setInvalidGateway] = React.useState(false);

  const updateGatewayId = (event) => {
    // Validate to ensure that it's a number
    // if (isNaN(event.target.value)) {
    //   setInvalidGateway(true);
    //   setCheckinSuccess(false);
    //   setGatewayError(" - this needs to be a number");
    //   return;
    // }

    // Ensure that 'SIG-' is in the start of the gatewayID
    if (!event.target.value.substring(0, 4) === "SIG-") {
      setInvalidGateway(true);
      setGatewayError(`- Gateway ID must start with "SIG-"`);
      return;
    }

    // Validate to ensure that it's a number
    if (
      isNaN(event.target.value.substring(4)) ||
      event.target.value.substring(4).length > 8 ||
      event.target.value.length < 4
    ) {
      setInvalidGateway(true);
      setCheckinSuccess(false);
      setGatewayError(
        `- The data after "SIG-" must be a number of max 8 digits`
      );
      return;
    }

    // Update the value of the gatewayID
    setGatewayID(event.target.value);

    setInvalidGateway(false);
    setCheckinSuccess(false);
    setGatewayError("");
  };

  // Successful checkin
  const [checkinSuccess, setCheckinSuccess] = React.useState(false);

  const submitForm = () => {
    // Validate to ensure that it's a number
    if (
      isNaN(gatewayID.substring(4)) ||
      invalidGateway ||
      invalidGateway == null
    ) {
      setInvalidGateway(true);
      setGatewayError(
        `- Gateway ID must start with "SIG-" & the data after "SIG-" must be a number of max 8 digits`
      );
      return;
    }

    // Ensure that 'SIG-' is in the start of the gatewayID
    if (!gatewayID.substring(0, 4) === "SIG-") {
      setInvalidGateway(true);
      setGatewayError(`- Gateway ID must start with "SIG-"`);
      return;
    }

    // Validate to ensure that it's a number
    if (
      isNaN(gatewayID.substring(4)) ||
      gatewayID.substring(4).length > 8 ||
      gatewayID.length <= 4
    ) {
      setInvalidGateway(true);
      setCheckinSuccess(false);
      setGatewayError(
        `- The data after "SIG-" must be a number of max 8 digits`
      );
      return;
    }

    // Submit the mutation
    client
      .mutate({
        mutation: generateCheckInGatewayMutation({
          id: gatewayID.substring(4),
        }),
        errorPolicy: "all",
      })
      .then(({ data, errors, loading }) => {
        // Handle errors if there are any
        if (errors) {
          console.error("ERROR " + JSON.stringify(errors));

          return;
        }

        // Transaction succeeded
        setCheckinSuccess(true);
      });
  };

  return (
    <>
      <GridContainer justify="center">
        <GridItem>
          <Card className={classes.cardSignup} style={{ width: "80%" }}>
            <h2 className={classes.cardTitle}>Check-in Gateway</h2>
            <p
              className={classes.cardTitle}
              style={{ paddingLeft: 150, paddingRight: 150 }}
            >
              You should have received your gateway, which, when first turned on
              and connected to the Internet, will have registered its ID with
              our servers.
              <br />
              <br />
              In the event that has not happened, you can still manually
              register its ID here, by submitting the Gateway ID that is
              presented on the physical gateway label.
            </p>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={5}>
                  <form className={classes.form}>
                    <CustomInput
                      labelText={`Gateway ID ${gatewayError}`}
                      id="gatewayID"
                      error={invalidGateway}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.InputAdornment}
                          >
                            <Tooltip
                              id="gateway-tooltip"
                              title="This can be found on the label attached to your gateway"
                              placement="left"
                              classes={{ tooltip: tooltipClasses.tooltip }}
                            >
                              <Fingerprint
                                className={classes.inputAdornmentIcon}
                              />
                            </Tooltip>
                          </InputAdornment>
                        ),
                        onChange: updateGatewayId,
                        onBlur: updateGatewayId,
                        defaultValue: gatewayID,
                        placeholder: "Gateway ID...",
                      }}
                    />
                    {checkinSuccess ? (
                      <>
                        <div className={classes.textCenter}>
                          <Success>
                            <h4>
                              <b>
                                Successfully Checked In Gateway: {gatewayID}
                              </b>
                            </h4>
                          </Success>
                          <Link to={`/register/${gatewayID.substring(4)}`}>
                            <Button color="primary">
                              Register Gateway {gatewayID}
                            </Button>
                          </Link>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={classes.textCenter}>
                          <Button color="primary" onClick={() => submitForm()}>
                            Submit
                          </Button>
                        </div>
                      </>
                    )}
                  </form>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default Gateway;
