import React from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";

import slideInOutAnim from "../animations/slideInOut.json";
import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle";
import { makeStyles } from "@material-ui/core";
import image from "assets/img/background_sensata_2.png";

import Countdown from "react-countdown";
// GraphQL
import ggl from "graphql-tag";
import { useSubscription } from "react-apollo";
// import client from "../api/client";

// Subscribe to get the status of the project
const projectStatusSubscription = ({ id }) => {
  return ggl`
    subscription {
      projectStateChange(id: "${id}") {
        state
        ipAddress
        gateway {
          state
        }
      }
    }
  `;
};

const Success = () => {
  const useStyles = makeStyles(landingPageStyle);
  const classes = useStyles();
  const location = useLocation();
  const email = location.state.email;
  const projectID = location.state.projectID;

  const { data, loading } = useSubscription(
    projectStatusSubscription({ id: projectID })
  );

  const countdownRenderer = ({ minutes, seconds, completed }) => {
    if (!loading && data.projectStateChange.state === "ready") {
      // Render the provisened state
      return (
        <>
          <h3 className={classes.title}>
            Your SCADA server has been successfully provisioned and is now
            available to use with the credentials you have given us earlier.{" "}
          </h3>
          <br />
          <a href={`http://${data.projectStateChange.ipAddress}/`}>
            <Button className="btn-simple" color="primary">
              Login to your SCADA Server
            </Button>
          </a>
        </>
      );
    } else if (completed) {
      // Render the timed-out state
      return (
        <>
          <h3 className={classes.title}>
            It seems to be taking longer than expected to setup your new SCADA
            server.
            <br />
            If you do not receive an email from us in the next 10 minutes,
            please contact <b>scada_support@sensata.com</b> referencing your
            project id.
          </h3>
        </>
      );
    } else {
      // Render the coundown message
      return (
        <>
          <h3 className={classes.title}>
            SCADA server is being provisioned at the moment <br />& we'll send
            an email to your address
            {email ? <> ({email})</> : <></>} when it's ready. <br /> <br />
            We're expecting this to complete in{" "}
            <span>
              {minutes}:{seconds < 10 && seconds > -1 ? `0${seconds}` : seconds}
            </span>
            , at which point we will validate it has <br />
            started correctly and also display its information here.
          </h3>
        </>
      );
    }
  };

  return (
    <div
      className={classes.pageHeader}
      style={{
        backgroundImage: "url(" + image + ")",
        backgroundSize: "cover",
        backgroundPosition: "top center",
      }}
    >
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem>
            <motion.div
              className={classes.brand}
              variants={slideInOutAnim}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <h1>Thank you for registering the gateway!</h1>
              <Countdown
                date={Date.now() + 600000} // 10 mins
                renderer={countdownRenderer}
                zeroPadTime={2}
              />
              <div>
                <Link to="/">
                  <Button className="btn-simple" color="warning">
                    Home
                  </Button>
                </Link>
              </div>
            </motion.div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
};

export default Success;
