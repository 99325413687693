import React from "react"
import {Route, Switch, useLocation} from "react-router-dom"
import {AnimatePresence} from 'framer-motion'
import { makeStyles } from "@material-ui/core/styles";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";
import Home from './routes/Home'
import Register from './routes/Register' 
import Gateway from './routes/Gateway' 
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Success from "routes/Success";
import HeaderLinks from "components/Header/HeaderLinks.js";

import footerStyles from "assets/jss/material-kit-pro-react/views/componentsSections/preFooter.js";
import logo from "assets/img/logo_sensata_2.png"
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

function App() {
    const location = useLocation();

    return (
        <>
            <div>
                <Header
                    brand={<img src={logo} alt="SENSATA" />}
                    links={<HeaderLinks dropdownHoverColor="info" />}
                    fixed
                    color="white"
                    changeColorOnScroll={{
                    height: 400,
                    color: "white"
                    }}
                />
                {/* Routes */}
                

                    <AnimatePresence exitBeforeEnter >
                        <Switch location={location} key={location.key}>
                            <Route exact path="/">
                                <Home />
                            </Route>
                            <Route exact path="/register/:gatewayId">
                                <Register />
                            </Route>
                            <Route exact path="/success">
                                <Success />
                            </Route>
                            <Route exact path="/gateway">
                                <Gateway />
                            </Route>
                        </Switch>
                    </AnimatePresence>

                    <FooterComponent />
            </div>
        </>
    )
}

const FooterComponent = () => {
    const useFooterStyles = makeStyles(footerStyles);
    const footerClasses = useFooterStyles();
    
    return <>
        <Footer 
            theme="dark"
            content={
                <div className={footerClasses.container}>
                    <GridContainer>
                        <GridItem xs={4} sm={4} md={4}>
                            <a
                                href="https://www.sensata.com/resources/privacy-policy"
                                target="blank_"
                            >
                                <b>PRIVACY POLICY</b>
                            </a>
                        </GridItem>
                    </GridContainer>
                    <GridContainer style={{float: "right"}}>
                        <GridItem >
                            Join the conversation
                        </GridItem>
                        <GridItem xs={2} sm={4} md={4}>
                            <a
                                href="https://www.facebook.com/SensataTechnologies"
                                target="blank_"
                            >
                                <i className={"fab fa-facebook"}/>
                            </a>
                        </GridItem>
                        <GridItem xs={2} sm={4} md={4}>
                            <a
                                href="https://www.linkedin.com/company/sensata-technologies/"
                                target="blank_"
                            >
                                <i className={"fab fa-linkedin"}/>
                            </a>
                        </GridItem>
                        <GridItem xs={2} sm={4} md={4}>
                            <a
                                href="https://twitter.com/Sensata"
                                target="blank_"
                            >
                                <i className={"fab fa-twitter"}/>
                            </a>
                        </GridItem>
                    </GridContainer>
                    <br/>
                    <GridContainer style={{float: "left"}}>
                        <GridItem>
                        ©2020 Sensata Technologies, Inc. All rights reserved. 
                        </GridItem>
                    </GridContainer>
                </div>
            }
        />
    </>;
}


export default App;